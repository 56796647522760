import { Box, FormLabel, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import axiosInstance from "../../../../helpers/api";
import { getAllLecturesRequest } from "../../../../modules/admin-modules/lectures/Actions";
import { getAllExamsRequest } from "../../../../modules/admin-modules/exams/Actions";
import { closeCreatePaymentModel } from "../../../../modules/admin-modules/models/Actions";
import {
  getAllVideosRequest,
  getPaymentSubjectRequest,
} from "../../../../modules/admin-modules/videos/Actions";

const AddPayment = ({ isAdd, selectedData, setSelectedData }) => {
  const dispatch = useDispatch();
  const paymentSubjects = useSelector((state) => state.videos.paymentSubjects);
  const videos = useSelector((state) => state.videos.videos);
  const lectures = useSelector((state) => state.lectures.lectures);
  const exams = useSelector((state) => state.exams.exams);

  const openEvent = useSelector((state) => state.model.isOpen19);
  const [isLoading, setLoading] = useState(false);

  const examsOptions = exams?.map((data) => {
    return { value: data.id, label: data.name };
  });

  useEffect(() => {
    if (openEvent) {
      dispatch(getPaymentSubjectRequest());
      dispatch(getAllVideosRequest());
      dispatch(getAllLecturesRequest());
      dispatch(getAllExamsRequest());
    }
  }, [openEvent]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
    shouldUnregister: true,
  });



  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        دفع
      </Heading>
    </Box>
  );
  const onSubmit = (data) => {
    const video_id = data?.video_id?.map((val) => val?.value);
    const body = {
      ...data,
      attend: data?.attend ? data?.attend : "0",
      is_paid: data?.is_paid ?? false,
      user_id: selectedData?.id,
      video_id,
    };
    const action = () => {
      dispatch(closeCreatePaymentModel());
      setSelectedData({});
      reset();
      toast.success("Payment successfully made.");
      setLoading(false);
    };

    setLoading(true);
    axiosInstance
      .post(`/admin/attendence/scan`, body)
      .then(() => {
        action();
      })
      .catch((err) => {
        toast?.error(err.response.data.message);
        setLoading(false);
      });
  };
  const lecturesOptions = lectures?.map((data) => {
    return { value: data.id, label: data.title };
  });
  const PaymentsOptions = paymentSubjects?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const videosOptions = videos?.map((data) => {
    return { value: data.id, label: data.title };
  });

  const closeFunc = () => {
    dispatch(closeCreatePaymentModel());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openEvent}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
          <MainInput
            label="مبلغ الدفع"
            error={errors?.paid_amount?.message}
            register={register}
            name="paid_amount"
            type="number"
          />
          <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
            <FormLabel>دفع : </FormLabel>
            <Box width="100%">
              <Select
                options={PaymentsOptions}
                onChange={(data) => setValue("payment_subject_id", data.value)}
              />
            </Box>
          </VStack>
          {isAdd && (
            <>
              <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
                <FormLabel>الفيديوهات : </FormLabel>
                <Box width="100%">
                  <Select
                    options={videosOptions}
                    onChange={(data) => setValue("video_id", data)}
                    isMulti
                  />
                </Box>
              </VStack>

              <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
                <FormLabel>الامتحان: </FormLabel>
                <Box width="100%">
                  <Select
                    options={examsOptions}
                    onChange={(data) => setValue("exam_id", data.value)}
                  />
                </Box>
              </VStack>
            </>
          )}

          <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
            <FormLabel>الحصص : </FormLabel>
            <Box width="100%">
              <Select
                options={lecturesOptions}
                onChange={(data) => setValue("lec_id", data.value)}
              />
            </Box>
          </VStack>



          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="20px"
          >
            <label>
              <input
                {...register("attend", { required: false })}
                type="radio"
                value={"1"}
              />
              قام بالحضور
            </label>
            <label>
              <input
                {...register("attend", { required: false })}
                type="radio"
                value={"0"}
                defaultChecked={true}
              />
              لم يقم بالحضور
            </label>

            <Text color="red">{errors?.type?.attend}</Text>
          </Stack>
          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="20px"
          >
            <label>
              <input
                {...register("is_paid", { required: false })}
                type="radio"
                value={true}
              />
              قام بالدفع
            </label>
            <label>
              <input
                {...register("is_paid", { required: false })}
                type="radio"
                value={false}
                defaultChecked={true}
              />
              لم يقم بالدفع
            </label>

            <Text color="red">{errors?.type?.is_paid}</Text>
          </Stack>
          <MainInput
            label="اضف تعليقك"
            error={errors?.comment?.message}
            register={register}
            name="comment"
            type="text"
            required={false}
          />
        </VStack>
      </form>
    </MainModel>
  );
};

export default AddPayment;
