import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { BiLowVision, BiShow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  facultiesAction,
  signupUserAction,
  yearsAction,
} from "../../modules/auth/Actions";
import PublicLayout from "../public/Layout/layout";
import circlesLogo from "../../assets/teacher-data/circles.jpg";
const SignupForm = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const years = useSelector((state) => state.auth.years);
  const faculties = useSelector((state) => state.auth.faculties);

  const [inputType, setInputType] = useState("password");

  const yearsOptions = years?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const facultiesOptions = faculties?.map((data) => {
    return { value: data.id, label: data.name };
  });
  useEffect(() => {
    dispatch(yearsAction());
    dispatch(facultiesAction());
  }, []);

  const validateUserName = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validatePhone = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }

    return error;
  };

  const validateConfirmPassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validateYear = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };

  const validateFacilities = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  return (
    <PublicLayout color="main_1" bg="white" register={false}>
      <Flex
        flexDirection="column"
        alignItems="center"
        my="50px"
        w="100%"
        h="100%"
      >
        <Formik
          initialValues={{
            name: "",
            phone: "",
            password: "",
            faculty_id: "",
            year_id: "",
            profile_url: "",
            profile_url2: "",
            terms: false,
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            if (!values.profile_url || !values.profile_url2)
              toast.error("upload your files");
            else if (!values.terms)
              toast.error("you must agree to the terms and conditions");
            else {
              formData.append("name", values.name);
              formData.append("phone", values.phone);
              formData.append("password", values.password);
              formData.append("faculty_id", values.faculty_id);
              formData.append("year_id", values.year_id);
              formData.append("profile_url", values.profile_url);
              formData.append("profile_url2", values.profile_url2);
              formData.append(
                "password_confirmation",
                values.password_confirmation
              );
              formData.append("terms", values.terms);

              dispatch(signupUserAction(formData));

              // set timeout then redirect to otp page
              setTimeout(() => {
                window.location.href = "/otp";
              }, 5000);
            }
          }}
        >
          {({ setValues, errors, touched, values, setFieldValue }) => (
            <Form
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flex
                flexDirection={"column"}
                sx={{ width: { base: "90%", md: "40%" } }}
              >
                <center>
                  <img
                    src={circlesLogo}
                    alt="circles logo"
                    style={{ width: "50%" }}
                  />
                </center>
                <Text textAlign="center" fontSize="20px" mt="20px">
                  Powered by Asquera
                </Text>
                <br />
                <Heading textAlign={"center"} mb="10px">
                  Sign Up
                </Heading>

                <Box w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="name"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Name
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="name"
                    validate={validateUserName}
                  />
                  {errors.name && touched.name && (
                    <Text color="red">{errors.name}</Text>
                  )}
                </Box>

                <Box w="100%" mt="35px">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="phone"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Phone
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="phone"
                    type="text"
                    validate={validatePhone}
                  />
                  {errors.phone && touched.phone && (
                    <Text color="red">{errors.phone}</Text>
                  )}
                </Box>

                <Box w="100%" mt="35px">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="email"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Email
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="email"
                    type="email"
                  />
                </Box>

                <Box w="100%" mt="35px">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Year
                    </FormLabel>
                  </Flex>{" "}
                  <Field
                    as="select"
                    name="year_id"
                    style={{
                      width: "100%",
                      padding: "13px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    validate={validateYear}
                  >
                    <option>Select</option>
                    {yearsOptions?.map((data, index) => {
                      return (
                        <option value={data?.value} key={index}>
                          {data?.label}
                        </option>
                      );
                    })}
                  </Field>
                  {errors.year_id && touched.year_id && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.year_id}
                    </Text>
                  )}
                </Box>

                <Box w="100%" mt="35px">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="faculty_id"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Faculty
                    </FormLabel>
                  </Flex>{" "}
                  <Field
                    as="select"
                    name="faculty_id"
                    style={{
                      width: "100%",
                      padding: "13px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    validate={validateFacilities}
                  >
                    <option>Select</option>
                    {facultiesOptions?.map((data, index) => {
                      return (
                        <option value={data?.value} key={index}>
                          {data?.label}
                        </option>
                      );
                    })}
                  </Field>
                  {errors.faculty_id && touched.faculty_id && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.faculty_id}
                    </Text>
                  )}
                </Box>

                <Box mt="35px" position={"relative"} w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Password
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    name="password"
                    validate={validatePassword}
                    type={inputType}
                  />

                  <Icon
                    position={"absolute"}
                    right="10px"
                    top="50px"
                    fontSize="33px"
                    color="main_1"
                    as={inputType === "password" ? BiShow : BiLowVision}
                    onClick={() =>
                      inputType === "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  />
                  {errors.password && touched.password && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.password}
                    </Text>
                  )}
                </Box>

                <Box mt="35px" position={"relative"} w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password_confirmation"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Confirm Password
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    name="password_confirmation"
                    validate={validateConfirmPassword}
                    type={inputType}
                  />

                  <Icon
                    position={"absolute"}
                    right="10px"
                    top="50px"
                    fontSize="33px"
                    color="main_1"
                    as={inputType === "password" ? BiShow : BiLowVision}
                    onClick={() =>
                      inputType === "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  />
                  {errors.password_confirmation &&
                    touched.password_confirmation && (
                      <Text color="red" w="180px" textAlign="justify">
                        {errors.password_confirmation}
                      </Text>
                    )}
                </Box>
                <HStack mt="35px">
                  <FormLabel htmlFor="profile_url" className="form-label ">
                    صورة اثبات الشخصية *
                  </FormLabel>
                  <Button
                    bg="main_1"
                    color="white"
                    borderRadius="8px"
                    textAlign="center"
                    position="relative"
                    w="50%"
                  >
                    <Text>
                      {values.profile_url?.name
                        ? values.profile_url?.name
                        : "اختر ملف ليتم رفعه"}
                    </Text>
                    <Input
                      type="file"
                      placeholder="اختر ملف ليتم رفعه"
                      opacity="0"
                      position="absolute"
                      w="100%"
                      h="100%"
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        setFieldValue("profile_url", e.target.files[0]);
                      }}
                    />
                  </Button>
                  <FormLabel htmlFor="profile_url" className="form-label ">
                    صورة ايصال الحجز *
                  </FormLabel>
                  <Button
                    bg="main_1"
                    color="white"
                    borderRadius="8px"
                    textAlign="center"
                    position="relative"
                    w="50%"
                  >
                    <Text>
                      {values.profile_url2?.name
                        ? values.profile_url2?.name
                        : "اختر ملف ليتم رفعه"}
                    </Text>
                    <Input
                      type="file"
                      placeholder="اختر ملف ليتم رفعه"
                      opacity="0"
                      position="absolute"
                      w="100%"
                      h="100%"
                      onChange={(e) => {
                        setFieldValue("profile_url2", e.target.files[0]);
                      }}
                    />
                  </Button>
                </HStack>

                {/* button to view a pdf of the terms and conditions */}
                {/* <Button
                  mt="20px"
                  w="250px"
                  p="22px"
                  alignSelf={"center"}
                  bg="main_1"
                  color="white"
                  onClick={() =>
                    window.open("https://asquera.com/asquera/circles_terms.pdf", "_blank")
                  }
                >
                  View Terms and Conditions
                </Button> */}

                <Text textAlign="center" mt="20px">
                  أقر أن كل بياناتي صحيحة و أنني صاحب الهوية التي أدخلتها أثناء
                  التسجيل وأن رقم الهاتف المسجل لدي الأبليكيشن مسئوليتي الشخصية
                  وأنني لن أحاول تسجيل الفيديوهات بأي وسيلة أو محاولة اخذ سكرين
                  شوت وأن أي محاولة لهذا تعرضني للحرمان من استخدام الأبليكيشن و
                  عدم استرداد المبلغ المدفوع مع اتخاذ كافة الإجراءات
                  القانونية والتعويض
                </Text>
                <Box mt="20px">
                  <Checkbox
                    colorScheme="green"
                    onChange={(e) => setFieldValue("terms", e.target.checked)}
                  >
                    I agree to the terms and conditions
                  </Checkbox>
                </Box>

                <Button
                  mt="40px"
                  type="submit"
                  w="250px"
                  p="22px"
                  alignSelf={"center"}
                  isLoading={isLoading}
                  bg="main_1"
                  color="white"
                >
                  SignUp
                </Button>

                <Text textAlign="center" mt="20px">
                  Already have an account?{" "}
                  <a
                    href="/circles/login"
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    Login
                  </a>
                </Text>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </PublicLayout>
  );
};

export default SignupForm;
