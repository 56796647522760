import Types from "./Types";

export const loginUserAction = (formData) => ({
  type: Types.LOGIN_USER,
  payload: formData,
});

export const loginUserSuccessAction = (user, token) => ({
  type: Types.LOGIN_USER_SUCCESS,
  payload: { user, token },
});

export const signupUserAction = (formData) => ({
  type: Types.SIGNUP_USER,
  payload: formData,
});

export const signupUserSuccessAction = (user, token) => ({
  type: Types.SIGNUP_USER_SUCCESS,
  payload: { user, token },
});

export const yearsAction = (formData) => ({
  type: Types.YEARS,
  payload: formData,
});

export const yearsSuccessAction = (payload) => ({
  type: Types.YEARS_SUCCESS,
  payload,
});

export const facultiesAction = (formData) => ({
  type: Types.FACULTIES,
  payload: formData,
});

export const facultiesSuccessAction = (payload) => ({
  type: Types.FACULTIES_SUCCESS,
  payload,
});

export const userVoucherAction = (formData) => ({
  type: Types.USER_VOUCHER,
  payload: formData,
});

export const userVoucherSuccessAction = (user, token) => ({
  type: Types.USER_VOUCHER_SUCCESS,
  payload: { user, token },
});

export const logoutUserAction = () => ({
  type: Types.LOGOUT_USER,
});

export const logoutUserSuccessAction = () => ({
  type: Types.LOGOUT_USER_SUCCESS,
});

export const setAuthIsLoadingAction = (isLoading) => ({
  type: Types.SET_AUTH_IS_LOADING,
  payload: isLoading,
});
