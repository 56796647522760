import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import CirclesStudentsTable from "../../../containers/admin/students/circlesStudents";
import AddStudent from "../../../containers/admin/students/models/add-student";
import ShowPassword from "../../../containers/admin/students/models/show-password";
import StudentReport from "../../../containers/admin/students/models/student-report";
import UserQRCode from "../../../containers/admin/students/models/user-qr-code";
import SearchBar from "../../../containers/admin/students/searchbar";
import StudentsTable from "../../../containers/admin/students/table";

const Students = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model.isOpen18);
  const [addedStudent, setAddedStudent] = useState({});
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const userData = useSelector((state) => state.user.userData);
  console.log(userData);
  return (
    <Layout>
      <SearchBar
        setAdd={setAdd}
        setSelectedData={setSelectedData}
        setSearchTerm={setSearchTerm}
      />
      {userData?.id === 24591 ? (
        <CirclesStudentsTable
          setAdd={setAdd}
          setSelectedData={setSelectedData}
          searchTerm={searchTerm}
        />
      ) : (
        <StudentsTable
          setAdd={setAdd}
          setSelectedData={setSelectedData}
          searchTerm={searchTerm}
        />
      )}

      <AddStudent
        isAdd={isAdd}
        data={selectedData}
        setAddedStudent={setAddedStudent}
      />
      <UserQRCode data={selectedData} setSelectedData={setSelectedData} />
      <ShowPassword data={addedStudent} />
      {model && <StudentReport student_id={selectedData?.id} />}
    </Layout>
  );
};

export default Students;
